import { useState } from "react";
import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";
import styles from "./LicensePlantWidget.module.scss";
import { Icon } from "@circle/kip-components";
import { LicenseEntry } from "./licenseEntry/LicenseEntry";

const LicensePlantWidget = props => { // eslint-disable-line
    const [isOpen, setOpen] = useState(false);
    const [items, setItems] = useState(props.licenses);

    const onChange = (val, index) => {
        const updatedFilteredItems = [...items];

        updatedFilteredItems[index] = val;
        setItems(updatedFilteredItems);
        props.onChangeLicenses(updatedFilteredItems);
    };

    return (
        <div className={styles.accordionWrapper}>
            <div
                className={resolveClassNames(styles.accordionTitle, isOpen ? styles.open : "")}
                onClick={() => setOpen(!isOpen)}
            >
                <span className="form-header font-bold main-header">{ props.name }</span>
                <Icon _icon="ChevronUp" className={resolveClassNames(styles.icon, isOpen ? (styles.open, styles.iconRev) : "")}/>
            </div>
            <div className={resolveClassNames(styles.accordionItem, !isOpen ? styles.collapsed : "")}>
                <div className={styles.accordionContent}>
                    <ol>
                        {items.map((item, index) =>
                            <LicenseEntry
                                key={index}
                                license={item}
                                onChange={val => onChange(val, index)}
                            />
                        )}
                    </ol>
                </div>
            </div>
        </div>
    );
};

LicensePlantWidget.propTypes = {
    name:               PropTypes.string,
    licenses:           PropTypes.arrayOf(PropTypes.object),
    locale:             PropTypes.string,
    expiryDate:         PropTypes.string,
    id:                 PropTypes.string,
    onSubmit:           PropTypes.func,
    onCancel:           PropTypes.func,
    onChangeLicenses:   PropTypes.func,
    onChangeExpiryDate: PropTypes.func
};

export { LicensePlantWidget };
