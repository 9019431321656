import { useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { Column } from "./Column";
import { Row } from "./Row";
import { Modal, Button } from "@circle/kip-components";

const Datatable = props => { // eslint-disable-line max-statements
    const [clickedId, setClicked] = useState(null);
    const [isOpen, setOpen]       = useState(false);
    const { translate }           = useTranslation();
    const { filter } = useBranch({
        filter: ["filter"]
    });

    const columns      = props.children.filter(elem => elem.type.prototype === Column.prototype);
    const content      = props.content;
    const filtered     = columns.filter(x => !x.props.hidden);
    const columnWidths = filtered.filter(x => !x.props.hidden).map(elem => props.hasActionsColumn || props.isEditable ? `${parseInt(elem.props.width, 10) - 10 / props.children.length}%` : elem.props.width);
    const selectedItem = props.content.find(x => x.id === clickedId);

    const onClick = (elem, e) => {
        props.onRowClick(elem, e);
    };

    const onEditClick = (elem, e) => {
        e.stopPropagation();

        props.onEditClick(elem);
    };

    const onDeleteClick = id => {
        setClicked(id);
        setOpen(true);
    };

    const closeModal = () => {
        setClicked(null);
        setOpen(false);
    };

    const onDelete = id => {
        props.onDelete(id);
        closeModal();
    };

    const onHeadlineClick = item => {
        props.onHeadlineClick(item);
    };

    return (
        <div className="datatable briddle font-bold">
            <table>
                <thead>
                    <tr>
                        <th className="placeholder"/>
                        {
                            columns.map((elem, idx) => (
                                <th
                                    className={`grey-colored font-medium ${elem.props.sortable ? "sortable clickable" : ""}`}
                                    key={idx}
                                    style={{ width: columnWidths[idx] }}
                                    onClick={() => onHeadlineClick(elem.props.filter || elem.props.item)}
                                >
                                    { (translate(elem.props.title)).toUpperCase() }
                                    {!(elem.props.sortable && (elem.props.item || elem.props.filter) === filter.selected) ? null : <img className="filter-arrow" src={`/images/right-arrow-${filter.order === "asc" ? "1" : "2"}.png`} />}
                                </th>
                            ))
                        }
                        {
                            (props.hasActionsColumn || props.isEditable) &&
                            <th className="placeholder" style={{ width: "10%", minWidth: "90px" }}/>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        content.length === 0 &&
                        <tr className="empty">
                            <td colSpan={props.children.length + 1}>{ translate("datatable.empty") }</td>
                        </tr>
                    }
                    {
                        content.map((elem, idx) => {
                            return (
                                <Row
                                    isEditable={props.isEditable}
                                    isDeletable={props.isDeletable}
                                    hasActionsColumn={props.hasActionsColumn}
                                    onDelete={() => onDeleteClick(elem.id)}
                                    index={idx}
                                    key={idx}
                                    content={elem}
                                    active={props.selected && props.selected === elem.id}
                                    onClick={() => onClick(elem)}
                                    columnWidths={columnWidths}
                                    onEdit={() => onEditClick(elem)}>
                                    { filtered }
                                </Row>
                            );
                        })
                    }
                </tbody>
            </table>
            <Modal
                title={translate("modal.delete.title")}
                isOpen={Boolean(isOpen && selectedItem)}
                onClose={closeModal}
                isCloseable={false}
            >
                <div className="flex-column">
                    <span className="font-bold font-3 mt-3">{ translate("modal.delete.danger") }</span>
                    <span className="font-bold mt-3">{ translate("modal.delete.really") }</span>
                    <div className="flex mt-5 justify-end">
                        <Button onClick={ closeModal }>
                            <span>{translate("actions.cancel")}</span>
                        </Button>
                        <Button _appearance="primary" onClick={() => onDelete(clickedId)}>
                            <span>{translate("actions.delete")}</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

Datatable.defaultProps = {
    onRowClick:      x => x,
    onDelete:        x => x,
    onHeadlineClick: x => x,
    onEditClick:     x => x,
    isDeletable:     true
};

Datatable.propTypes = {
    children:         PropTypes.node,
    content:          PropTypes.arrayOf(PropTypes.object),
    isEditable:       PropTypes.bool,
    isDeletable:      PropTypes.bool,
    onDelete:         PropTypes.func,
    onDrop:           PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    onRowClick:       PropTypes.func,
    onHeadlineClick:  PropTypes.func,
    onEditClick:      PropTypes.func,
    selected:         PropTypes.string
};

export { Datatable };
