import PropTypes from "prop-types";
import { Icon, Button } from "@circle/kip-components";

const Row = props => {
    const onDelete = (e, id) => {
        e.stopPropagation(e);

        props.onDelete(id);
    };

    return (
        <tr className={`row${!props.active ? " clickable" : " active"}`} onClick={props.onClick}>
            <td className="placeholder" />
            {
                props.children.map((x, index) => (
                    <td key={props.index * props.children.length + index} style={{ width: props.columnWidths[index] }}>
                        <div className="flex">
                            {
                                x.props.render &&
                                x.props.render(props.content[x.props.item])
                            }
                            {
                                !x.props.render &&
                                <span>{props.content[x.props.item]}</span>
                            }
                        </div>
                    </td>
                ))
            }
            {
                (props.hasActionsColumn || props.isEditable) && props.isDeletable &&
                <td style={{ width: "10%" }}>
                    {
                        props.isEditable &&
                        <Button _variant="icon" onClick={e => onDelete(e, props.content.id)}>
                            <Icon _icon="Trashcan"/>
                        </Button>
                    }
                </td>
            }
        </tr>
    );
};

Row.propTypes = {
    children:         PropTypes.node,
    index:            PropTypes.number,
    content:          PropTypes.object,
    onClick:          PropTypes.func,
    active:           PropTypes.bool,
    isEditable:       PropTypes.bool,
    isDeletable:      PropTypes.bool,
    onDelete:         PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    columnWidths:     PropTypes.arrayOf(PropTypes.string)
};

export { Row };
