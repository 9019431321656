import { useBranch } from "@circle/gestalt-app";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "./Menu";
import { Content } from "./Content";
import { Root as CompRoot, rootClassName } from "@circle/kip-components";
import { SidebarHost } from "@circle/kip-components";
import { Router } from "./Router";
import "../styles.scss";

const Root = props => {
    const { init, history } = useBranch({
        init:    ["init"],
        history: ["history"]
    });

    useEffect(() => {
        document.getElementById("default-loading-anchor").remove();
    }, []);

    return (
        <Router history={history} id={props.id}>
            <CompRoot>
                <div className={`flex ${rootClassName}`}>
                    <SidebarHost>
                        <Menu/>
                        {
                            init &&
                            <Content/>
                        }
                    </SidebarHost>
                </div>
            </CompRoot>
        </Router>
    );
};

Root.propTypes = {
    id: PropTypes.string
};

export { Root };
