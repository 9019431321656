import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./licenseEntry.module.scss";
import { Button, Icon, Modal, TextInput } from "@circle/kip-components";
import CalendarModule from "react-calendar";
import { useTranslation } from "@circle/gestalt-app";

const LicenseEntry = props => { // eslint-disable-line
    const { translate } = useTranslation();

    const [modal, setModal]           = useState(false);
    const [value, setValue]           = useState(null);
    const [license, setLicense]       = useState(props.license);

    const onClose = () => {
        setModal(false);
    };

    const handleItemChange = newLicense => {
        newLicense.expiryDate = value;

        setLicense(newLicense);
        props.onChange(newLicense);

        setModal(false);
    };

    const onDelete = newLicense => {
        setValue(null);
        newLicense.expiryDate = null;

        setLicense(newLicense);
        props.onChange(newLicense);
    };

    return (
        <li>
            <div className={styles.formsContainer}>
                <div className="flex-column flex-grow justify-center">
                    <span className="form-header font-bold">{license.label}</span>
                </div>
                <div className="flex-column flex-grow">
                    <span className={styles.timeSpan}>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={license.expiryDate ? new Date(license?.expiryDate)?.toLocaleString().replace(",", "").replaceAll("/", ".") : ""}
                            icon="DatePicker"
                            placeholder="--.--.----"
                            onFocus={() => {
                                setValue(license?.expiryDate);
                                setModal(true);
                            }}
                        />
                        <button className={styles.deleteButton} onClick={e => {
                            e.stopPropagation(e);
                            onDelete(license);
                        }}>
                            <Icon _icon="Trashcan"/>
                        </button>
                    </span>
                </div>
            </div>

            <Modal
                title={translate("modal.select.date")}
                isOpen={modal}
                isCloseable={false}
                onClose={() => setModal(false)}
            >
                <CalendarModule
                    onChange={x => setValue(x)}
                    value={value}
                />
                <div className="mt-10 horizontal-end">
                    <Button onClick={onClose}>
                        <span>{ translate("actions.cancel")}</span>
                    </Button>
                    <Button _appearance="primary" onClick={() => handleItemChange(license)}>
                        <span>{ translate("actions.select")}</span>
                    </Button>
                </div>
            </Modal>
        </li>
    );
};

LicenseEntry.propTypes = {
    name:               PropTypes.string,
    license:            PropTypes.object,
    id:                 PropTypes.string,
    onSubmit:           PropTypes.func,
    onCancel:           PropTypes.func,
    onChange:           PropTypes.func,
    onChangeExpiryDate: PropTypes.func
};

export { LicenseEntry };
